import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/react';
import { initializeSentry } from 'common/components/Sentry/Sentry';
import ToastProvider from 'apps/commerce/common/checkout/context/toast/ToastProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { initialValues, validationSchema } from 'apps/commerce/common/checkout/constants';
import { useForm, FormProvider } from 'react-hook-form';
import { SageBreakpointProvider, SageTokens } from '@kajabi/sage-react';
import CartContainer from '../../../apps/commerce/cart/CartContainer';

export function FormContainer({ children }: { children: React.ReactNode }) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { ...initialValues },
    resolver: yupResolver(validationSchema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
}

document.addEventListener('DOMContentLoaded', () => {
  const cartRoot = document.querySelector('#cart-root');

  // if there is no cartRoot, we don't need to do anything
  if (!cartRoot) return;

  initializeSentry({ tags: { team: 'Commerce', component: 'cart' } });

  document.body.classList.add('sage-excluded');

  ReactDom.render(
    <Sentry.ErrorBoundary>
      <SageBreakpointProvider queries={SageTokens.BREAKPOINT_QUERIES}>
        <ToastProvider>
          <FormContainer>
            <CartContainer />
          </FormContainer>
        </ToastProvider>
      </SageBreakpointProvider>
    </Sentry.ErrorBoundary>,
    cartRoot,
  );
});
