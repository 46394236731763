import { Price } from 'apps/commerce/cart/types';

// Super basic for now. Just add the two amounts. Does not care about currency or exponent. Assuming
// they are the same for both prices.
export const addPrices = (price1: Price, price2: Price): Price => ({
  amount: price1.amount + price2.amount,
  currency: price1.currency,
  exponent: price1.exponent,
});

export const discountPrice = (price1: Price, price2: Price): Price => ({
  amount: price1.amount - price2.amount,
  currency: price1.currency,
  exponent: price1.exponent,
});
