import { useEffect, useState } from 'react';
import cartClient from 'apps/commerce/cart/utils/cartClient';
import { Cart, Coupon } from 'apps/commerce/cart/types';
import CheckoutSettings from 'apps/commerce/common/checkout/types/CheckoutSettings';

interface UseCartTaxesProps {
  checkoutSettings?: CheckoutSettings;
  addressCountry?: string;
  addressZip?: string;
  taxId?: string;
  coupon?: Coupon | null;
  updateCartState: (updater: (prevCart: Cart | undefined) => Cart | undefined) => void;
}

const useCartTaxes = ({
  checkoutSettings,
  addressCountry,
  addressZip,
  taxId,
  coupon,
  updateCartState,
}: UseCartTaxesProps): any => {
  const [calculatingTax, setCalculatingTax] = useState(false);

  useEffect(() => {
    // Clear the cart taxes if not taxable, the country is messing or the zip is missing
    if (!checkoutSettings?.taxable || !addressCountry || !addressZip) {
      updateCartState((prevCart) => (prevCart ? { ...prevCart, taxes: null } : prevCart));
      return;
    }

    setCalculatingTax(true);

    const timer = setTimeout(() => {
      cartClient
        .calculateTax(addressCountry, addressZip, taxId)
        .then((res) => {
          const taxes = res.data;
          updateCartState((prevCart) => (prevCart ? { ...prevCart, taxes } : prevCart));
        })
        .catch(() => {
          updateCartState((prevCart) => (prevCart ? { ...prevCart, taxes: null } : prevCart));
        })
        .finally(() => setCalculatingTax(false));
    }, 1000);
    return () => clearTimeout(timer); // eslint-disable-line consistent-return
  }, [checkoutSettings, addressCountry, addressZip, taxId, coupon, updateCartState]);

  return {
    calculatingTax,
  };
};

export default useCartTaxes;
