import { Button, Modal, SageTokens } from '@kajabi/sage-react';
import React from 'react';
import { useCart } from './CartContext';

const CartModal = () => {
  const {
    context,
    modal: { component: Component, props },
    hideModal,
  } = useCart();
  const { cart, step } = context;
  const isCheckout = step === 1;
  const [logoWidth, setLogoWidth] = React.useState(50);

  React.useLayoutEffect(() => {
    const headerLogo = document.querySelector('.logo__image');
    if (headerLogo) {
      setLogoWidth(Math.min(headerLogo.clientWidth, 100));
    }
  }, []);

  const logo = cart?.site.logoImageUrl ? (
    <img style={{ width: `${logoWidth}px` }} alt="site-logo" src={cart?.site.logoImageUrl} />
  ) : (
    cart?.site.title || null
  );
  return (
    Component && (
      <Modal
        active={true}
        fullScreen={true}
        className={`cart ${isCheckout ? 'checkout-step' : ''}`}
        role="dialog"
      >
        <Modal.Header
          className="site-title"
          title={logo}
          aside={
            <Button
              color={Button.COLORS.SECONDARY}
              onClick={hideModal}
              icon={SageTokens.ICONS.REMOVE}
              iconOnly
              raised="false"
              subtle
            >
              Close
            </Button>
          }
        />
        <Modal.Body>
          <Component {...props} />
        </Modal.Body>
      </Modal>
    )
  );
};

export default CartModal;
