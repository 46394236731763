export const disableScroll = () => {
  const $html = document.querySelector('html') as HTMLElement;
  const $body = document.querySelector('body') as HTMLElement;
  $html.classList.add('stop-scroll--html');
  $body.classList.add('stop-scroll--body');

  // Set header to 100% height to prevent the Cart modal from being cut off
  const stickyHeader = document.querySelector('.header.sticky') as HTMLElement;
  if (stickyHeader) {
    stickyHeader.style.height = '100%';
  }
};

export const enableScroll = () => {
  const $html = document.querySelector('html') as HTMLElement;
  const $body = document.querySelector('body') as HTMLElement;
  $html.classList.remove('stop-scroll--html');
  $body.classList.remove('stop-scroll--body');
  const stickyHeader = document.querySelector('.header.sticky') as HTMLElement;
  if (stickyHeader) {
    stickyHeader.style.height = '';
  }
};

export const disableButton = (button: HTMLAnchorElement) => {
  button.style.pointerEvents = 'none';
  button.style.opacity = '0.5';
  button.style.cursor = 'not-allowed';
};

export const enableButton = (button: HTMLAnchorElement) => {
  button.style.pointerEvents = 'auto';
  button.style.opacity = '1';
  button.style.cursor = 'pointer';
};
