import { Cart, CartTaxes, Price } from 'apps/commerce/cart/types';
import { useEffect } from 'react';
import { addPrices, discountPrice } from 'apps/commerce/cart/utils/prices';

interface UseCartTotalProps {
  subtotal?: Price;
  discountAmount?: Price;
  taxes?: CartTaxes;
  taxInclusive?: boolean;
  updateCartState: (updater: (prevCart: Cart | undefined) => Cart | undefined) => void;
}

const useCartTotal = ({
  subtotal,
  discountAmount,
  taxes,
  taxInclusive,
  updateCartState,
}: UseCartTotalProps): void => {
  useEffect(() => {
    if (!subtotal) return;

    let cartTotal = subtotal;

    if (discountAmount) {
      cartTotal = discountPrice(cartTotal, discountAmount);
    }

    if (!taxInclusive) {
      cartTotal = taxes?.totalTaxAmount ? addPrices(cartTotal, taxes.totalTaxAmount) : cartTotal;
    }

    // Update the cart state with the new total
    updateCartState((prevCart) => (prevCart ? { ...prevCart, totalPrice: cartTotal } : prevCart));
  }, [subtotal, taxes, taxInclusive, updateCartState]);
};

export default useCartTotal;
