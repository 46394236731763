import React from 'react';
import { Button } from '@kajabi/sage-react';
import { PdsIcon } from '@pine-ds/react';
import Cart from 'apps/commerce/cart/Cart';
import i18next from 'apps/commerce/translations/i18n';
import { useTranslation } from 'react-i18next';
import ModalComponent from './CartModal';
import { useCart } from './CartContext';
import CartProvider from './CartProvider';

function CartButton() {
  const { showModal, context, cartCount } = useCart();
  const { t } = useTranslation();

  const { cart } = context;

  if (!cart) return null;

  const hasItems = cart.items.length > 0;
  const icon = hasItems ? 'shopping-cart-filled' : 'cart';
  return (
    <div className="cart-button sage-scoped">
      <Button
        className="cart-button__wrap"
        subtle
        onClick={() => showModal(Cart)}
        aria-label={t('messages.cart.toggle_cart')}
      >
        <div className="cart-button__inner">
          <PdsIcon icon={icon} />
          {hasItems && (
            <div
              aria-label={`${t('messages.cart.total_items_in_cart')}`}
              className="cart-button__count"
            >
              {cartCount}
            </div>
          )}
        </div>
      </Button>
    </div>
  );
}

export const CartContainer = () => {
  i18next; // eslint-disable-line no-unused-expressions
  return (
    <CartProvider>
      <CartButton />
      <ModalComponent />
    </CartProvider>
  );
};

export default CartContainer;
