import { useEffect } from 'react';
import CheckoutSettings from 'apps/commerce/common/checkout/types/CheckoutSettings';
import { useFormContext } from 'react-hook-form';
import cartClient from 'apps/commerce/cart/utils/cartClient';

interface UseTaxIdStatusProps {
  checkoutSettings?: CheckoutSettings;
  addressCountry?: string;
  taxId?: string;
}

const useTaxIdStatus = ({ checkoutSettings, addressCountry, taxId }: UseTaxIdStatusProps): void => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (
      !checkoutSettings?.taxable ||
      !checkoutSettings?.countriesToValidateTaxId ||
      !addressCountry ||
      !taxId
    ) {
      return;
    }

    if (!checkoutSettings.countriesToValidateTaxId.includes(addressCountry)) {
      return;
    }

    const timer = setTimeout(() => {
      cartClient
        .fetchTaxIdStatus(addressCountry, taxId)
        .then((res) => {
          setValue('taxIdValidationStatus', res.data.status);
        })
        .catch(() => {
          console.warn('Failed loading tax ID status');
        });
    }, 1000);
    return () => clearTimeout(timer); // eslint-disable-line consistent-return
  }, [checkoutSettings, addressCountry, taxId]);
};

export default useTaxIdStatus;
